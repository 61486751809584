var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.form)?_c('div',[_c('b-container',{staticClass:"container-box"},[_c('b-row',{staticClass:"no-gutters"},[_c('b-col',[_c('h1',{staticClass:"font-weight-bold header-main text-uppercase mb-3"},[_vm._v(" อะคาเดมี่ "+_vm._s(_vm.academyNum)+" ")])])],1),_c('div',{staticClass:"bg-white p-3"},[_c('b-row',{staticClass:"pl-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"panel"},[_c('b-form-checkbox',{staticClass:"mt-0 mt-sm-3",attrs:{"size":"lg","id":"sameLang"},on:{"change":_vm.useSameLanguage},model:{value:(_vm.form.staticPage.isSameLanguage),callback:function ($$v) {_vm.$set(_vm.form.staticPage, "isSameLanguage", $$v)},expression:"form.staticPage.isSameLanguage"}},[_vm._v("ใช้เหมือนกันทุกภาษา ")])],1)]),_c('b-col',{staticClass:"text-right"},[_c('div',{staticClass:"panel"},_vm._l((_vm.languageList),function(language,index){return _c('b-button',{key:index,staticClass:"btn btn-language",class:[_vm.languageActive == language.id ? 'active' : ''],attrs:{"type":"button","disabled":_vm.form.staticPage.isSameLanguage ? true : false},on:{"click":function($event){return _vm.changeLanguage(language.id, index)}}},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(language.nation))])])}),1)])],1),(_vm.$isLoading)?_c('b-row',[_c('b-col',[_vm._l((_vm.form.staticPage.translationList),function(item,index){return _c('div',{key:index},[_c('div',{class:[
                _vm.languageActive == item.languageId ? '' : 'd-none',
              ]},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('InputText',{attrs:{"textFloat":"ชื่อ","placeholder":"ชื่อ","type":"text","name":"name","img":_vm.imageLogoLang,"isRequired":"","isValidate":_vm.$v.form.staticPage.translationList.$each.$iter[index]
                        .name.$error,"v":_vm.$v.form.staticPage.translationList.$each.$iter[index]
                        .name},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),(item.description != null)?_c('TextEditor',{attrs:{"textFloat":"คำอธิบาย","rows":8,"value":item.description,"name":'description_' + item.languageId,"img":_vm.imageLogoLang,"placeholder":"Type something...","isRequired":"","v":_vm.$v.form.staticPage.translationList.$each.$iter[index]
                        .description,"imgTypeId":_vm.imgTypeId},on:{"onDataChange":(val) => (item.description = val)}}):_vm._e()],1)],1)],1)])}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('UploadFile',{attrs:{"textFloat":"ภาพหน้าปก","placeholder":"กรุณาเลือกไฟล์เพื่ออัพโหลด","format":"image","name":"thumbnail","text":"*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 500 x 500 px ขนาดไม่เกิน 10 MB","isRequired":"","fileName":_vm.form.staticPage.imageUrl},on:{"onFileChange":_vm.onImageChange,"delete":_vm.deleteImage}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"preview-box b-contain",style:({
                  'background-image': 'url(' + _vm.form.staticPage.imageUrl + ')',
                })},[(_vm.isLoadingImage)?_c('img',{staticClass:"loading",attrs:{"src":"/img/loading.svg","alt":"loading"}}):_vm._e()])])],1)],2)],1):_vm._e(),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{staticClass:"text-sm-right",attrs:{"md":"6"}},[_c('button',{staticClass:"btn btn-main btn-details-set ml-md-2 text-uppercase",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.checkForm(1)}}},[_vm._v(" บันทึก ")])])],1)],1),_c('ModalAlert',{ref:"modalAlert",attrs:{"text":_vm.modalMessage}}),_c('ModalAlertError',{ref:"modalAlertError",attrs:{"text":_vm.modalMessage}}),_c('ModalLoading',{ref:"modalLoading",attrs:{"hasClose":false}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }